/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import HorizontalSearch from './components/HorizontalSearch';
Vue.component('horizontal-search', HorizontalSearch);


import store from './vuex/search'

Vue.config.productionTip = false;

window.indexpage = new Vue({
    el: '#app',
    store: store
});
