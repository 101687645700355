export const pushNewKidsAge = (state) => {
    state.people.kidsage.push({
        'age': 12
    })
}

export const remmoveKidsAge = (state) => {
    if(state.people.kidsage.length > 0) {
        let lastIndex = state.people.kidsage.length
        state.people.kidsage.splice(lastIndex - 1, 1)
    }
}

export const changeShowPrice = (state, data) => {
    state.booking.showPrice = data
}