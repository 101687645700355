<template>
    <div class="kidsage-box">
        <select class="form-control" v-model="people.kidsage[indexAge].age" @change="changeAge($event.target.value), showCheckPrice()">
            <option value="">Wiek dziecka w momencie wymeldowania</option>
            <option value="0">0 lat</option>
            <option value="1">1 rok</option>
            <option value="2">2 lata</option>
            <option value="3">3 lata</option>
            <option value="4">4 lata</option>
            <option value="5">5 lat</option>
            <option value="6">6 lat</option>
            <option value="7">7 lat</option>
            <option value="8">8 lat</option>
            <option value="9">9 lat</option>
            <option value="10">10 lat</option>
            <option value="11">11 lat</option>
            <option value="12">12 lat</option>
            <option value="13">13 lat</option>
            <option value="14">14 lat</option>
            <option value="15">15 lat</option>
            <option value="16">16 lat</option>
            <option value="17">17 lat</option>
        </select>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        props: {
            mode: String,
            indexAge: Number
        },
        data: () => ({

        }),
        computed: {
            ...mapGetters([
                'booking',
                'people'
            ])
        },
        methods: {
            changeAge(value) {
                if (value == '' || isNaN(value)) {
                    this.people.kidsage[this.indexAge].age = 12
                }
            },
            showCheckPrice() {
                if(this.mode == 'booking') {
                    this.$store.commit('changeCheckPriceButton', false);
                }
            }
        }
    }

</script>

<style lang="scss">
    .kidsage-box {
        margin-bottom: 15px;
    }
</style>
