export const getPrice = ({ commit }, payload) => {
    return axios.post(urlGetPrice, {
        params: {
            start: `${payload.start}`,
            end: `${payload.end}`,
            adults: `${payload.adults}`,
            kids: `${payload.kids}`,
            kidsages: `${payload.kidsages}`,
            room_id: `${payload.room_id}`
        },
        responseType: 'json'
    })
}