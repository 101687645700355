export default {
    booking: {
        showPrice: true,
    },
    dates: {
        start: null,
        end: null,
        originStart: null,
        originEnd: null
    },
    people:{
        adults: 1,
        kids: 0,
        kidsage: []
    }
}
