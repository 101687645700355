<template>
    <div class="add_people_box">
        <div class="inside">
            <div class="item">
                <label>Dorośli</label>
                <div class="select-wrapper">
                    <span class="prev" :class="{ zeronumber: people.adults == 1 }" @click="subAdults()">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
                            <path class="path-color" d="M20.3,15H8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h12.3c0.5,0,0.8,0.4,0.8,0.8S20.8,15,20.3,15z M24.2,24.2
                            c2.7-2.7,4.2-6.2,4.2-10c0-3.8-1.5-7.3-4.2-10l0,0l0,0C21.5,1.5,18,0,14.2,0s-7.3,1.5-10,4.2S0,10.4,0,14.2c0,3.8,1.5,7.3,4.2,10
                            c2.8,2.8,6.4,4.1,10,4.1S21.4,27,24.2,24.2z M23,5.3L23,5.3c2.4,2.4,3.7,5.5,3.7,8.8S25.4,20.7,23,23c-4.9,4.9-12.8,4.9-17.7,0
                            c-2.4-2.4-3.7-5.5-3.7-8.8S3,7.7,5.3,5.3s5.5-3.7,8.8-3.7S20.7,3,23,5.3z"/>
                        </svg>
                    </span>
                    <span class="number">{{ people.adults }}</span>
                    <span class="next" @click="addAdults()">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
                            <path class="path-color" d="M21.2,14.2c0,0.5-0.4,0.8-0.8,0.8H15v5.3c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8V15H8c-0.5,0-0.8-0.4-0.8-0.8
                            s0.4-0.8,0.8-0.8h5.3V8c0-0.5,0.4-0.8,0.8-0.8S15,7.6,15,8v5.3h5.3C20.8,13.3,21.2,13.7,21.2,14.2z M28.3,14.2c0,3.8-1.5,7.3-4.2,10
                            c-2.8,2.8-6.4,4.1-10,4.1s-7.3-1.4-10-4.1C1.5,21.5,0,18,0,14.2c0-3.8,1.5-7.3,4.2-10S10.4,0,14.2,0s7.3,1.5,10,4.2
                            C26.9,6.8,28.3,10.4,28.3,14.2z M26.7,14.2c0-3.3-1.3-6.5-3.7-8.8s-5.5-3.7-8.8-3.7S7.7,3,5.3,5.3s-3.7,5.5-3.7,8.8S3,20.7,5.3,23
                            c4.9,4.9,12.8,4.9,17.7,0C25.4,20.7,26.7,17.5,26.7,14.2z"/>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="item">
                <label>Dzieci</label>
                <div class="select-wrapper">
                    <span class="prev" :class="{ zeronumber: people.kids == 0 }" @click="subKids()">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
                            <path class="path-color" d="M20.3,15H8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h12.3c0.5,0,0.8,0.4,0.8,0.8S20.8,15,20.3,15z M24.2,24.2
                            c2.7-2.7,4.2-6.2,4.2-10c0-3.8-1.5-7.3-4.2-10l0,0l0,0C21.5,1.5,18,0,14.2,0s-7.3,1.5-10,4.2S0,10.4,0,14.2c0,3.8,1.5,7.3,4.2,10
                            c2.8,2.8,6.4,4.1,10,4.1S21.4,27,24.2,24.2z M23,5.3L23,5.3c2.4,2.4,3.7,5.5,3.7,8.8S25.4,20.7,23,23c-4.9,4.9-12.8,4.9-17.7,0
                            c-2.4-2.4-3.7-5.5-3.7-8.8S3,7.7,5.3,5.3s5.5-3.7,8.8-3.7S20.7,3,23,5.3z"/>
                        </svg>
                    </span>
                    <span class="number">{{ people.kids }}</span>
                    <span class="next" @click="addKids()">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
                            <path class="path-color" d="M21.2,14.2c0,0.5-0.4,0.8-0.8,0.8H15v5.3c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8V15H8c-0.5,0-0.8-0.4-0.8-0.8
                            s0.4-0.8,0.8-0.8h5.3V8c0-0.5,0.4-0.8,0.8-0.8S15,7.6,15,8v5.3h5.3C20.8,13.3,21.2,13.7,21.2,14.2z M28.3,14.2c0,3.8-1.5,7.3-4.2,10
                            c-2.8,2.8-6.4,4.1-10,4.1s-7.3-1.4-10-4.1C1.5,21.5,0,18,0,14.2c0-3.8,1.5-7.3,4.2-10S10.4,0,14.2,0s7.3,1.5,10,4.2
                            C26.9,6.8,28.3,10.4,28.3,14.2z M26.7,14.2c0-3.3-1.3-6.5-3.7-8.8s-5.5-3.7-8.8-3.7S7.7,3,5.3,5.3s-3.7,5.5-3.7,8.8S3,20.7,5.3,23
                            c4.9,4.9,12.8,4.9,17.7,0C25.4,20.7,26.7,17.5,26.7,14.2z"/>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="kids-age-box" v-if="people.kidsage.length > 0">
                <div class="headline">W jakim wieku są dzieci, z którymi podróżujesz?</div>
                <kids-age v-for="(value,index) in people.kidsage" :key="index" :indexAge="index" :mode="'search'"></kids-age>
            </div>
        </div>
    </div>
</template>

<script>

    import KidsAge from './KidsAge'
    import {mapGetters} from "vuex"

    export default {
        components: {
            KidsAge
        },
        data: () => ({

        }),
        computed: {
            ...mapGetters([
                'people'
            ])
        },
        methods: {
            addAdults() {
                this.people.adults = parseInt(this.people.adults) + 1
            },
            subAdults() {
                if(this.people.adults > 1) {
                    this.people.adults = parseInt(this.people.adults) - 1
                }
            },
            addKids() {
                this.people.kids = parseInt(this.people.kids) + 1
                this.$store.commit('pushNewKidsAge')
            },
            subKids() {
                if(this.people.kids > 0) {
                    this.people.kids = parseInt(this.people.kids) - 1
                    this.$store.commit('remmoveKidsAge');
                }
            },
            checkMinPeople() {
                let minPeople = parseInt(this.people.adults) + parseInt(this.people.kids)

                if(minPeople >= this.room.minperson) {
                    this.$store.commit('changeMinpersonError', false)
                }
            }
        }
    }

</script>

<style lang="scss">

</style>
