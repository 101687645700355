<template>
    <div class="search-bar-container">
        <div class="town" style="position:relative;" v-if="showtowns">
            <div class="town-name-box" @click="showTownBox = !showTownBox">
                <span>{{ townnazwa }}</span>
                <svg class="arrow" :class="{ showbox: showTownBox }" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 16.3" style="enable-background:new 0 0 28.3 16.3;" xml:space="preserve">
                    <path class="path-color" d="M13.4,16.3C13.4,16.3,13.4,16.3,13.4,16.3c-0.4,0-0.7-0.2-1-0.4L0.3,2.2c-0.5-0.5-0.4-1.4,0.1-1.9C1-0.1,1.8-0.1,2.3,0.4
                    L13.5,13L26.1,0.4c0.5-0.5,1.4-0.5,1.9,0s0.5,1.4,0,1.9L14.3,15.9C14.1,16.1,13.7,16.3,13.4,16.3z"/>
                </svg>
            </div>
            <div class="towns-list" v-if="showTownBox">
                <a
                    href="#"
                    v-for="miasto in townsSelect"
                    @click.prevent="townname(miasto.id)"
                    style="display: block;"
                >
                    {{ miasto.nazwa }}
                </a>
            </div>
            <input type="hidden" name="town" v-model="town">
        </div>
        <div :class="{ showstart: startDateInput, showend: endDateInput}" class="dates">
            <HotelDatePicker
                :format="`DD.MM.YYYY`"
                :startDate="startday"
                :endDate="endday"
                :firstDayOfWeek="1"
                :i18n="pl"
                :displayClearButton="false"
                :startingDateValue="startDateInput"
                :endingDateValue="endDateInput"
                @check-in-changed="logIn($event)"
                @check-out-changed="logOut($event)"
            ></HotelDatePicker>
        </div>
        <div class="people">
            <div class="info_people" @click="showPeopleBox = !showPeopleBox">
                <div class="adults">
                    Dorośli - <span>{{ people.adults }}</span>
                </div>
                <div class="kids">
                    Dzieci - <span>{{ people.kids }}</span>
                </div>
                <svg class="arrow" :class="{ showbox: showPeopleBox }" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.3 16.3" style="enable-background:new 0 0 28.3 16.3;" xml:space="preserve">
                    <path class="path-color" d="M13.4,16.3C13.4,16.3,13.4,16.3,13.4,16.3c-0.4,0-0.7-0.2-1-0.4L0.3,2.2c-0.5-0.5-0.4-1.4,0.1-1.9C1-0.1,1.8-0.1,2.3,0.4
                    L13.5,13L26.1,0.4c0.5-0.5,1.4-0.5,1.9,0s0.5,1.4,0,1.9L14.3,15.9C14.1,16.1,13.7,16.3,13.4,16.3z"/>
                </svg>
            </div>
            <add-people v-if="showPeopleBox"></add-people>
        </div>
        <div class="submit">
            <input type="hidden" v-model="startDateForm" name="przyjazd">
            <input type="hidden" v-model="endDateForm" name="wyjazd">
            <input type="hidden" v-model="people.adults" name="dorosli">
            <input type="hidden" v-model="kidsageForm" name="dzieci">
            <button type="submit">Szukaj</button>
        </div>
    </div>
</template>

<script>

    import HotelDatePicker from 'vue-hotel-datepicker'
    import AddPeople from './AddPeopleSearch'
    import {mapGetters} from "vuex"
    import format from 'date-fns/format'
    import addHours from 'date-fns/addHours'
    import has from 'lodash/has'
    import setHours from 'date-fns/setHours'
    import setMinutes from 'date-fns/setMinutes'
    import setSeconds from 'date-fns/setSeconds'


    export default {
        props: {
            startD: { // początek wybranego zakresu jak jest w sesji
                type: [String, Number],
                default: ''
            },
            endD: { // koniec wybranego zakresu jak jest w sesji
                type: [String, Number],
                default: ''
            },
            townId: { // koniec wybranego zakresu jak jest w sesji
                type: [String, Number],
                default: 0
            },
            calStart: {
                type: String,
                default: ''
            },
            calEnd: {
                type: String,
                default: ''
            },
            dataSession: {
                type: Object,
                default: []
            },
            towns: {
                type: Array,
                default: []
            },
            showtowns: {
                type: Boolean,
                default: true
            }
        },
        components: {
            AddPeople,
            HotelDatePicker
        },
        data: () => ({
            showPeopleBox: false,
            showTownBox: false,
            startday: false, // min zakres kalendarza
            endday: false, // max zakres kalendarza
            town: 0,
            townnazwa: 'Wszędzie',
            pl: {
                night: 'Noc',
                nights: 'Nocy',
                'day-names': ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Niedz'],
                'check-in': 'Przyjazd',
                'check-out': 'Wyjazd',
                'month-names': ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
            }
        }),
        computed: {
            ...mapGetters([
                'people',
                'dates'
            ]),
            startDateInput(){
                if (this.dates.originStart !== null) {
                    return this.dates.originStart
                } else {
                    return null
                }
            },
            endDateInput(){
                if (this.dates.originEnd !== null) {
                    return this.dates.originEnd
                } else {
                    return null
                }
            },
            startDateForm(){
                if (this.dates.originStart !== null) {
                    let properDate = this.dates.start.split(' ')
                    properDate = properDate[0].split('.')
                    if(properDate.length > 1) {
                        return properDate[2]+'-'+properDate[1]+'-'+properDate[0]
                    } else {
                        return properDate[0]
                    }
                } else {
                    return ''
                }
            },
            endDateForm(){
                if (this.dates.originEnd !== null) {
                    let properDate = this.dates.end.split(' ')
                    properDate = properDate[0].split('.')
                    if(properDate.length > 1) {
                        return properDate[2]+'-'+properDate[1]+'-'+properDate[0]
                    } else {
                        return properDate[0]
                    }
                } else {
                    return ''
                }
            },
            kidsageForm() {
                if(this.people.kids > 0) {
                    let arr = [];
                    for (var i = 0; i < this.people.kidsage.length; i++) {
                        arr.push(this.people.kidsage[i].age);
                    }
                    return arr.join()
                } else {
                    return ''
                }
            },
            townsSelect() {
                return this.towns
                    .filter((town) => {
                        if (parseInt(this.town) > 0) {
                            return town
                        }
                        if (parseInt(this.town) == 0) {
                            return town.id > 0
                        }
                    })
            }
        },
        methods: {
            townname(id){
                this.town = parseInt(id)

                if(parseInt(id) == 0) {
                    this.townnazwa = 'Wszędzie'
                } else {
                    let index = this.towns.findIndex(_event => _event.id == id)
                    this.townnazwa = this.towns[index].nazwa
                }

                this.showTownBox = !this.showTownBox
            },
            logIn(date) {
                //console.log(date)
                let newStartDate = addHours(date, 13)
                this.dates.start = format(newStartDate, 'dd.MM.yyyy HH:mm:ss')
                this.dates.originStart = date
            },
            logOut(date) {
                //console.log(date)
                if (date === null) {
                    this.dates.end = null
                    this.dates.originEnd = null
                } else {
                    const newEndDate = addHours(date, 13)
                    this.dates.end = format(newEndDate, 'dd.MM.yyyy HH:mm:ss')
                    this.dates.originEnd = date
                }
            }
        },
        mounted() {

            if(has(this.dataSession, 'town')) {
                this.town = parseInt(this.dataSession.town)
            } else {
                this.town = parseInt(this.townId)
            }

            if(this.townId > 0) {
                let index = this.towns.findIndex(_event => _event.id == parseInt(this.townId))
                this.townnazwa = this.towns[index].nazwa
            } else {
                this.townnazwa = 'Wybierz miejscowość'
            }

            if(has(this.dataSession, 'adults')) {
                this.people.adults = parseInt(this.dataSession.adults)
                this.people.kids = (this.dataSession.kids == '') ? 0 : parseInt(this.dataSession.kids)

                let arr = []
                for (var i = 0; i < this.dataSession.kidsages.length; i++) {
                    const obj = {}
                    obj['age'] = this.dataSession.kidsages[i]
                    arr.push(obj)
                }

                this.people.kidsage = arr

            }


        },
        created() {
            if(this.startD != 0) {
                let startgodz = setHours(new Date(this.startD), 13)
                startgodz = setMinutes(startgodz,0)
                startgodz = setSeconds(startgodz,0)

                this.dates.originStart = new Date(this.startD)
                this.dates.start = format(startgodz, 'yyyy-MM-dd HH:mm:ss')
            }

            if(this.endD != 0) {
                let endgodz = setHours(new Date(this.endD), 13)
                endgodz = setMinutes(endgodz,0)
                endgodz = setSeconds(endgodz,0)

                this.dates.originEnd = new Date(this.endD)
                this.dates.end = format(endgodz, 'yyyy-MM-dd HH:mm:ss')
            }

            // this.startday = new Date(this.calStart)
            this.startday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            let properDate = this.calEnd.split(' ')
            properDate = properDate[0].split('-')
            let miesiac = properDate[1].replace(/^0+/, '')
            let dzien = properDate[2].replace(/^0+/, '')

            this.endday = new Date(properDate[0], miesiac, dzien)
        }
    }

</script>

<style lang="scss">

    @import url('https://fonts.googleapis.com/css?family=Barlow:400,500&display=swap&subset=latin-ext');

    .search-bar-container {
        font-family: 'Barlow', sans-serif;
        border: 1px solid #d7d9e2;
        border-radius: 0px;
        min-height: 50px;
        background: #fff;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        margin-bottom: 20px;
        .dates {
            border-right: 1px solid #d7d9e2;
            align-items: center;
            justify-content: center;
        }
        .people {
            border-right: 1px solid #2c92cd;
            position: relative;
            .info_people {
                .adults {
                    margin-right: 15px;
                }
                .kids {
                    margin-right: 15px;
                }
                svg.arrow {
                    width: 15px;
                    height: 15px;
                    transition: all 0.2s ease-in;
                    &.showbox {
                        transform: rotate(180deg);
                    }
                }
                svg.ppl-icon {
                    width: 15px;
                    height: 15px;
                }
            }
        }
        & > div {
            flex-grow: 1;
            display: flex;
            justify-content: center;
        }
        .town, .dates, .people {
            width: calc(80% / 3);
            font-size: 1.70rem;
        }
        .submit {
            width: 20%;
            button {
                background: #2c92cd;
                border: 0;
                color: #fff;
                width: 100%;
                height: 100%;
                font-size: 1.97rem;
                &:hover {
                    background: darken(#2c92cd, 5%);
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .search-bar-container {
            flex-direction: column;
            .town, .dates, .people, .submit {
                width: 100%;
                padding: 15px 0;
            }
            .submit {
                width: 100%;
                button {
                    padding: 10px 0;
                }
            }
            .town, .dates, .people {
                border-right: 0;
            }
            .town, .dates {
                border-bottom: 1px solid #d7d9e2;
            }
        }
    }

    .search-bar-container .info_people {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .search-bar-container .dates {
        .datepicker__wrapper {
            background: none;
            height: auto;
            .datepicker__dummy-wrapper {
                border: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .datepicker__input {
                    text-align: center;
                    font-size: 1.70rem;
                    text-indent: 0;
                    padding: 0 5px;
                    height: auto;
                    float: none;
                }
            }
            .datepicker.datepicker--open {
                .datepicker__month {
                    font-size: 1.5rem;
                }
                .datepicker__months .datepicker__week-row .datepicker__week-name {
                    font-size: 1.5rem;
                }

                .datepicker__month-button {
                    &.datepicker__month-button--prev {
                        background: transparent url('/images/cal_left_arrow.svg') no-repeat 50%/8px;
                        transform: none;
                    }
                    &.datepicker__month-button--next {
                        background: transparent url('/images/cal_right_arrow.svg') no-repeat 50%/8px;
                    }
                }

                .datepicker__month .square {
                    &>div>div {
                        font-weight: 500;
                    }
                    .datepicker__month-day[style] {
                        border: 1px solid #2c92cd !important;
                    }
                    .datepicker__month-day--first-day-selected, .datepicker__month-day--last-day-selected {
                        background: lighten(#2c92cd, 30%);
                        color: darken(#2c92cd, 15%);
                        opacity: 1;
                    }
                    .datepicker__month-day--selected {
                        background-color: lighten(#2c92cd, 6%);
                        &:hover {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .dates {
        &.showstart, &.showend {
            .datepicker__input {
                position: relative;
                padding-top: 15px!important;
                &:before {
                    content: '';
                    position: absolute;
                    font-size: 1.2rem;
                    top: 0;
                    left: 32%;
                    color: #444444;
                }
            }
        }
        &.showstart .datepicker__input:first-child {
            color: #0047fc;
            &:before {
                content: 'Przyjazd';
            }
        }
        &.showend .datepicker__input {
            color: #0047fc;
            &:before {
                content: 'Wyjazd';
            }
        }
    }

    .search-bar-container .town {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #d7d9e2;
        div.town-name-box {
            span {
                margin-right: 50px;
            }
            svg.arrow {
                width: 15px;
                height: 15px;
                transition: all 0.2s ease-in;
                &.showbox {
                    transform: rotate(180deg);
                }
            }
        }
        .towns-list {
            position: absolute;
            left: -1px;
            top: 48px;
            width: calc(100% + 2px);
            z-index: 100;
            background: #fff;
            padding: 10px;
            border-bottom: 1px solid #d7d9e2;
            border-left: 1px solid #d7d9e2;
            border-right: 1px solid #d7d9e2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            a {
                margin: 0 10px;
                padding: 5px;
                &:hover {
                    background: #e4e4e4;
                }
            }
        }
    }

    .search-bar-container .people .add_people_box {
        position: absolute;
        left: -1px;
        top: 48px;
        width: calc(100% + 2px);
        z-index: 100;
        background: #fff;
        padding: 10px;
        border-bottom: 1px solid #d7d9e2;
        border-left: 1px solid #d7d9e2;
        border-right: 1px solid #d7d9e2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .inside {
            display: flex;
            flex-direction: column;
            width: 60%;
        }
        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            label {
                width: 20%;
                text-align: center;
            }
            &:nth-child(2) {
                margin-top: 20px;
                margin-bottom: 10px;
            }
            & > * {
                flex-grow: 1;
            }
        }
        .select-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            span.prev, span.next {
                cursor: pointer;
            }
            & > span {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    .kids-age-box {
        margin-top: 20px;
        .headline {
            font-size: 1.4rem;
            margin-bottom: 10px;
        }
    }

</style>
