export const people = (state) => {
    return state.people
}

export const dates = (state) => {
    return state.dates
}

// export const house = (state) => {
//     return state.house
// }
//
// export const room = (state) => {
//     return state.room
// }

export const booking = (state) => {
    return state.booking
}

// export const error = (state) => {
//     return state.error
// }